<template>
  <div class="collection_container">
    <div class="list">
      <div class="item"
           v-for="(item,index) in userCollectionRecordList"
           :key="item.id">
        <div class="top">
          <van-image class="avatar"
                     width="80"
                     height="80"
                     src="http://www.fkxrmyy.com/wxxcx/wx/image/doc.png"
                     fit="contain" />
          <div class="info">
            <div>科室：{{item.content.deptName}}</div>
            <div>姓名：{{item.content.doctorName}}</div>
            <div>任职：{{item.content.doctorTitle}}</div>
            <div :class="item.more ? 'more': '' "
                 @click="showMore($event)"
                 :id="index">个人简介：{{item.content.doctorIntrodution === ''?'无':item.content.doctorIntrodution}}</div>
          </div>
        </div>
        <div class="bottom">
          <van-button type="danger"
                      class="cancel"
                      @click="removeBtn(item.id)">取消收藏</van-button>
          <van-button type="info"
                      class="navRegister"
                      @click="navRegister(item.content.doctorCode)">预约挂号</van-button>
        </div>
      </div>
    </div>
    <van-empty description="暂无内容"
               v-if="userCollectionRecordList === ''" />
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Collection",
  data () {
    return {
      userCollectionRecordList: []
    };
  },
  created () {
    this.getUserCollectionRecordList();
  },
  methods: {
    //获取医生收藏列表
    async getUserCollectionRecordList () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId")
      });
      const { data: res } = await formPost(
        "/api/wxapp/record/userCollectionRecordList",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        let data = res.data;
        let list = [];
        for (let i = 0; i < data.length; i++) {
          let obj = {};
          for (let key in data[i]) {
            obj.id = key;
            obj.content = data[i][key][0];
            obj.more = true;
          }
          list.push(obj);
        }
        this.userCollectionRecordList = list;
      } else {
        this.userCollectionRecordList = "";
        this.$toast.fail(res.msg);
      }
    },
    //取消收藏
    async remove (id) {
      this.$toast.loading({
        duration: 0,
        message: "取消中...",
        forbidClick: true
      });
      let data = qs.stringify({
        id: id
      });
      const { data: res } = await formPost("/api/wxapp/record/remove", data);
      this.$toast.clear();
      if (res.code === 0) {
        this.$toast.success(res.msg);
        setTimeout(() => {
          this.getUserCollectionRecordList();
        }, 2000);
      } else {
        this.$toast.fail(res.msg);
      }
    },
    showMore (e) {
      let index = parseInt(e.currentTarget.id);
      this.userCollectionRecordList[index].more = !this
        .userCollectionRecordList[index].more;
    },
    removeBtn (id) {
      this.remove(id);
    },
    navRegister (doctorCode) {
      this.$router.push({
        path: "/register/doctor",
        query: {
          doctorCode: doctorCode
        }
      });
    }
  }
};
</script>

<style scope>
.collection_container .list {
  padding-bottom: 60px;
}
.collection_container .list .item {
  border-bottom: 10px solid #f5f5f5;
  padding: 20px;
  font-size: 40px;
}
.collection_container .list .item .top {
  display: flex;
}
.collection_container .list .item .top .avatar {
  margin-top: 20px;
  flex: 1;
}
.collection_container .list .item .top .info {
  flex: 4;
  margin-left: 30px;
  line-height: 70px;
}
.collection_container .list .item .top .info .more {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.collection_container .list .item .bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
}
.collection_container .list .item .bottom .navRegister,
.collection_container .list .item .bottom .cancel {
  font-size: 34px;
}
</style>

